import { onMounted, ref, Ref, onUnmounted } from 'vue'
import { useMediaQuery } from '@vueuse/core'

export function useStickyScroll () {
  const portalHeader: Ref<HTMLDivElement | undefined> = ref()
  const sideBarOffsetHeight = ref(130)
  const canShowSideBar = useMediaQuery('(min-width: 1024px)')

  onMounted(() => {
    window.addEventListener('scroll', calculateSideBarOffsetHeight)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', calculateSideBarOffsetHeight)
  })

  function calculateSideBarOffsetHeight () {
    if (portalHeader.value) {
      const portalHeaderBox = portalHeader.value.getBoundingClientRect()
      sideBarOffsetHeight.value = portalHeaderBox.bottom >= 0 ? portalHeaderBox.bottom : 0
    }
  }

  return { portalHeader, sideBarOffsetHeight, canShowSideBar }
}
